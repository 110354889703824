body {
  background-color: #eeeeee;
}

canvas {
  border-radius: 4px;
}

.home {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 25px;
}

.map-container {
  max-width: 1042px;
  height: 625px;
  margin: auto;
}
.mapDiv {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}
.iced {
  max-width: 1000px;
  margin: auto;
}
.header {
  max-width: 1042px;
  display: flex;
  flex-direction: row;
  padding: 5px;
  justify-content: center;
  margin-top: 25px;
  flex-wrap: wrap;
}
.header-title {
  font: normal normal 64px/64px "Arial Black", sans-serif;
  color: #4444DF;
  text-transform: uppercase;
}

.header div {
  margin-left: 10px;
  /* margin-right: 10px; */
}
.header img {
  width: 70px;
  /* border-radius: 20px; */
  border: solid 1px;
}
.header-subtitle {
  font: normal normal 14px/14px "Arial Black", sans-serif;
  color: #aaaaaa;
  margin-top: auto;
  margin-bottom: auto;
}

hr {
  max-width: 1042px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.about {
  display: flex;
  flex-direction: row;
  max-width: 1042px;
  flex-wrap: wrap;
  margin: auto;
  justify-content: space-around;
  font-size: small;
}
.about-section {
  width: 450px;
  /* min-width: 200px; */
  margin-bottom: 20px;
  margin-top: 20px;
  flex-direction: row;
  flex-wrap: wrap;
}
.indent {
  margin-left: 20px;
}

.about-header {
  font: normal normal 14px "Arial Black", sans-serif;
}

.esri-popup .esri-popup__main-container {
  width: 200px !important;
  border-radius: 4px;
}

.popup-styled {
  display: flex;
  flex-direction: column;
}
.popup-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.popup-button {
  margin-bottom: 3px;
  color: black !important;
  text-decoration: none;
  border: solid 3px #8bf6ff;
  background-color: #4fc3f7;
  /* width: 49%; */
  padding: 5px;
  cursor: pointer;
  justify-content: space-around;
  text-align: center;
  border-radius: 8px;
}
.popup-attribute {
  width: 90%;
  padding-top: 3px;
  /* text-transform: capitalize; */
}
h2.esri-widget__heading {
  /* display: none; */
}
.esri-popup__header {
  opacity: 1 !important;
}
.esri-view {
  /* doing this here instead of in MapView JS because it wasn't applied in safari */
  background-color: black;
  border-radius: 5px;
}

.esri-view-height-small .esri-expand .esri-widget--panel,
.esri-view-height-small .esri-expand .esri-widget--panel-height-only,
.esri-view-height-small .esri-ui-corner .esri-component.esri-widget--panel,
.esri-view-height-small
  .esri-ui-corner
  .esri-component.esri-widget--panel-height-only {
  width: 265px;
  padding: 3px;
  margin: 0px;
  border-radius: 4px;
}
.esri-layer-list__item-container {
  padding: 3px !important;
  border-radius: 4px;
  cursor: pointer;
  font-size: small;
}

.highRes {
  margin: 4px 3px 0px 3px !important;
}

.esri-layer-list__list {
  padding: 0px 3px 0px 3px !important;
}
.esri-layer-list__item {
  border-radius: 4px;
}
#layersContainer button {
  background-color: white;
  border: solid 3px #8bf6ff;
  margin: auto;
  float: right;
}
.not-logged-in small {
  color: black;
}
.not-logged-in {
  color: grey;
}
.not-logged-in:hover {
  color: black;
  text-decoration: underline;
}
